<template>
  <!-- partnerSite ? 'The Ethos Performance Intelligence' : 'FI Analytics' -->
  <!-- partnerSite ? [] : -->
  <PageCard
    pageName="FI Analytics"
    :iconClass="['cuis-chart-line', 'card-icon-font']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <div class="ps-2">
                <div class="row card-row mx-0">
                  <div class="col-9">
                    <h1 class="card-title d-inline-block pr-3 banner-title">
                      FI Analytics
                    </h1>
                    <div class="solution-icon same-col soln-img"></div>
                  </div>
                </div>
                <p class="card-text mb-4 soln-desc pl-3">
                  Position Your Services as Solutions
                </p>
              </div>

              <FeatureDetailsCard
                title="FIN Advisor"
                description1="Deploy FIN Advisor to align your account planning with your client’s strategic planning – positioning your solutions as C-suite priorities."
                description2="Instantly generate a complete consultative assessment of any FI’s profit, growth, risk, &amp; offering – generating primary opportunity themes, specific strengths/weaknesses and even custom solution category recommendations."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_A"
              />
              <FeatureDetailsCard
                title="FIN Reporter"
                description1="Utilize FIN Reporter® to engage institutions with differentiating meeting content that resonates – creating the impetus for action."
                description2="FIN Reporter delivers complete control to users on reports, metrics, peers, time spans and graphics to create custom content. Transform unprecedented data into presentations that garner C-suite attention."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_R"
              />
              <FeatureDetailsCard
                title="FIN Profile"
                description1="Access FIN Profile® to instantly get up to speed on any institution."
                description2="Quickly access high-level facts on profit, growth, risk, & offering. Condense in-depth analyses into fast facts on any FI’s firmographics, performance & known vendor-products deployed. Engage FI prospects with the confidence enabled by FIN Profile’s rapid due diligence."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_P"
              />
            </div>
          </div>
        </section>
        <UseCaseDetails></UseCaseDetails>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'
import UseCaseDetails from './UseCaseDetails'

export default {
  name: 'AboutAnalytics',
  components: {
    PageCard,
    FeatureDetailsCard,
    UseCaseDetails
  },
  data() {
    return {
      VC_SOL_FIN_A: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A12.png'
      ],
      VC_SOL_FIN_R: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_R1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_R2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_R3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_R4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_R5.png'
      ],
      VC_SOL_FIN_P: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P6.png'
      ]
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Analytics'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.solution-section {
  .soln-img {
    background: url('../../../../static/img/icon-benchmarking.png');
    background-size: cover;
    // margin-bottom: 10px;
  }
}
</style>
